import { useEffect, useMemo, useState } from "react";
import { CustomAlertConfigPayloadType, CustomAlertConfigType, RegionType } from "../../sdk/types/customAlertConfig.type";
import CustomCheckbox from "../CustomCheckbox/CustomCheckboxComponent";
import CustomButton from "../CustomButton/CustomButtonComponent";
import CustomSelect from "../CustomSelect/CustomSelectComponent";
import useCustomDialogHandler, { IDialogProps } from "../CustomDialog/useCustomDialogHandler";
import { CameraConfigType } from "../../sdk/types/cameraConfig.type";
import Quadrilateral, { defaultCoordinateList } from "../Quadrilateral/QuadrilateralComponent";
import floorHotspotVideo from '../../assets/videos/floor-hotspot1.mp4';
import floorHotspotImage from '../../assets/images/floor-hotspot1.png';
import CustomDialog from "../CustomDialog/CustomDialogComponent";
import './CustomAlertConfigComponent.scss';
import customAlertConfigService from "../../sdk/services/customAlertConfigService";
import { updateCoordinateListFromPercentageToScale, updateCoordinateListFromScaleToPercentage } from "../../utils/common.util";
import SvgIcon, { iconNameTypes } from "../SvgIcons/SvgIconComponent";
import ToastService from "../Toast/ToastService";
import QuadrilateralOverlay, { defaultPoints } from "../QuadrilateralOverlay/QuadrilateralOverlay";

const shapeList = [
    {
        name: 'rectangle',
        iconName: 'shapeRectangle'
    }
]

interface ICustomAlertConfigProps {
    selectedCamera: CameraConfigType;
}

const CustomAlertConfig: React.FC<ICustomAlertConfigProps> = ({ selectedCamera }) => {
    const { openDialog, handleOpenDialog, handleCloseDialog, handleCancelDialog, handleSaveDialog } = useCustomDialogHandler();
    const [customAlertConfigDetails, setCustomAlertConfigDetails] = useState<CustomAlertConfigType | null>(null);
    const [dialogProps, setDialogProps] = useState<IDialogProps | null>(null);
    const [selectedShapeToDraw, setSelectedShapeToDraw] = useState<string | null>(null);
    const [drawnCoordinates, setDrawnCoordinates] = useState<{ coordinateX: string; coordinateY: string; }[] | null>(null);
    const [formData, setFormData] = useState<CustomAlertConfigPayloadType>({
        alertName: '',
        regionName: '',
        alertType: 'occupancy_changed',
        enabled: false,
        severity: 'Severity Level 1',
        params: [],
        coordinates: []
    });

    useEffect(() => {
        setFormData({
            alertName: '',
            regionName: '',
            alertType: 'occupancy_changed',
            enabled: false,
            severity: 'Severity Level 1',
            params: [],
            coordinates: []
        });
        setSelectedShapeToDraw(null);
        const fetchSafetyConfigList = async () => {
            try {
                if (selectedCamera?.id) {
                    const alertConfig = await customAlertConfigService.getAllCustomAlert(selectedCamera?.id);
                    console.log(alertConfig);
                    updateFormDataOnGettingApiResponse(alertConfig);
                }
            } catch (error) {

            } finally {

            }
        }
        fetchSafetyConfigList();
    }, [selectedCamera]);

    // useEffect(() => {
    //     // // const config: CustomAlertConfigType = formData || {} as CustomAlertConfigType;
    //     // const config: CustomAlertConfigType = {
    //     //     ...customAlertConfigDetails,
    //     //     // alertName: formData.alertName,
    //     //     // alertType: formData.alertType,
    //     //     // cameraName: '',
    //     //     // enabled: formData.enabled,
    //     //     id: customAlertConfigDetails?.id || '',
    //     //     region: {
    //     //         coordinates: formData.coordinates
    //     //     } as RegionType
    //     // } as CustomAlertConfigType;
    //     // setCustomAlertConfigDetails(config);
    // }, [formData]);

    useEffect(() => {
        proximityAreaDialog();
    }, [selectedShapeToDraw]);

    const updateFormDataOnGettingApiResponse = (alertConfig: CustomAlertConfigType[]) => {
        const config: CustomAlertConfigType = alertConfig[0] || {} as CustomAlertConfigType;
        setCustomAlertConfigDetails(alertConfig?.length ? config : null);
        updateFormDataWithAPIResultOrDefault(config);
    }

    const updateFormDataWithAPIResultOrDefault = (config: CustomAlertConfigType) => {
        const formDataToSet = {
            alertName: config.alertName || '',
            regionName: config?.region?.regionName || '',
            alertType: (config?.alertType as "occupancy_under" | "occupancy_changed") || 'occupancy_changed',
            enabled: config?.enabled || false,
            severity: config?.severity || 'Severity Level 1',
            params: config?.params || [],
            coordinates: config?.region?.coordinates || []
        };
        setFormData(formDataToSet);
    }

    const updateFormDatabyNameAndValue = (name: string, value: any) => {
        setFormData(prevState => {
            const updatedFormData = {
                ...prevState,
                [name]: value,
            };
            return updatedFormData;
        })
        // if (name == 'enabled') {
        //     setIsCreateCustomAlertEnabled(value);
        // }
    }

    const handleOnChangeCustomAlertCheckbox = async (enabled: boolean) => {
        setFormData(prevData => ({
            ...prevData,
            enabled: enabled,
        }));
        if (customAlertConfigDetails?.id) {
            try {
                await customAlertConfigService.updateCustomAlertConfig(customAlertConfigDetails.id, { enabled });
                ToastService.showToast('Update custom alert', 'Successfully updated the custom alert', 'success');
            } catch (error: any) {
                const { errorCode, errorInfo } = error?.response?.data;
                let errorMessage = errorInfo || 'Something went wrong';
                ToastService.showToast('Update custom alert', errorMessage, 'error');
            }
        }
    }

    const handleCoordinatesChange = (points: { x: number; y: number }[]) => {
        // You can perform further actions with the updated coordinates here
        // percentageToScale();
        const updatedPoints = updateCoordinateListFromPercentageToScale(points);
        console.log('updatedPoints:: ', updatedPoints);
        updateFormDatabyNameAndValue('coordinates', updatedPoints);
    };

    const handleQuadrilateralCoordinatesChange = (points: { coordinateX: string; coordinateY: string }[]) => {
        // You can perform further actions with the updated coordinates here
        // percentageToScale();
        // const updatedPoints = updateCoordinateListFromPercentageToScale(points);
        // const updatedPoints = points.map(p => ({ coordinateX: p.coordinateX.toString(), coordinateY: p.coordinateY.toString() }));
        const updatedPoints = points;
        console.log('updatedPoints:: ', updatedPoints);
        // updateFormDatabyNameAndValue('coordinates', updatedPoints);
        // setDrawnCoordinates(updatedPoints);
        setFormData(prevState => {
            const updatedFormData = {
                ...prevState,
                coordinates: updatedPoints,
            };
            return updatedFormData;
        })
    };

    const handleOnShapeSelectedToDraw = (shapeName: string) => {
        setSelectedShapeToDraw(shapeName);
    }

    const proximityAreaRenderer = () => {
        return (
            <section className="proximity-area">
                <header className="shapes">
                    <label>Area types:</label>
                    <ul>
                        {
                            shapeList.map((data, index: number) => (
                                <li key={data.name}
                                    onClick={() => handleOnShapeSelectedToDraw(data?.name)}
                                    className={selectedShapeToDraw == data?.name ? 'selected' : ''}>
                                    <SvgIcon name={data.iconName as iconNameTypes} />
                                </li>
                            ))
                        }
                    </ul>
                </header>
                <div className="content">
                    {/* <Quadrilateral
                        mediaType="video"
                        pointsVisible={!!selectedShapeToDraw}
                        mediaSrc={floorHotspotVideo}
                        defaultCoordinates={defaultCoordinateList}
                        showLinesAndFill={!!selectedShapeToDraw}
                        onCoordinatesChange={handleCoordinatesChange} /> */}

                    <section className="drawing-area">
                        <QuadrilateralOverlay
                            mediaUrl={floorHotspotVideo}
                            // mediaUrl={'https://antmedia.workersafety.aiotdemos.com:5443/WorkerSafetyDev/play.html?id=qualcomm-device-554'}
                            // isIframe={true}
                            isVideo={true}
                            canDrag={!!selectedShapeToDraw}
                            showLines={!!selectedShapeToDraw}
                            initialPoints={defaultPoints}
                            onPointsChange={handleQuadrilateralCoordinatesChange}
                        />
                    </section>

                </div>
            </section>
        )
    }

    const proximityAreaDialog = () => {
        setDialogProps({
            title: `Create Proximity Area - Cam ID - ${selectedCamera?.cameraId}, ${selectedCamera?.location}`,
            content: proximityAreaRenderer(),
            dialogMaxWidth: 'md',
            isSaveDisabled: false,
            cancelText: 'Cancel',
            saveText: 'Save',
            onSave: (data: any) => {
                // handleAddCamera()
                if (!formData?.coordinates?.length && selectedShapeToDraw) {
                    const pointsAsPercentages = defaultPoints.map(point => ({
                        coordinateX: parseFloat(point.coordinateX).toFixed(3),
                        coordinateY: parseFloat(point.coordinateY).toFixed(3),
                    }));
                    formData.coordinates = pointsAsPercentages;
                }
                handleCloseDialog();
            },
            onCancel: () => {
                setSelectedShapeToDraw(null);
                let configDetails: CustomAlertConfigType = customAlertConfigDetails || { enabled: true } as CustomAlertConfigType;
                updateFormDataWithAPIResultOrDefault(configDetails);
                handleCloseDialog();
            }
        })
    }

    const handleCreateProximityAreaBtnClick = () => {
        proximityAreaDialog();
        handleOpenDialog();
    }

    const customDialogRenderer = () => {
        if (!dialogProps) return null;
        return (
            <CustomDialog
                headerLabel={dialogProps.title}
                open={openDialog}
                content={dialogProps.content}
                dialogMaxWidth={dialogProps?.dialogMaxWidth || 'md'}
                cancelText={dialogProps.cancelText}
                saveText={dialogProps.saveText}
                isSaveDisabled={false}
                onClose={handleCloseDialog}
                onCancel={dialogProps.onCancel}
                onSave={dialogProps.onSave}
            />
        )
    }

    const handleChangeAlertName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        updateFormDatabyNameAndValue('alertName', value);
        updateFormDatabyNameAndValue('regionName', value);
    };

    const validateForm = (): boolean => {
        const { alertName, coordinates, regionName, severity } = formData;
        return (
            alertName?.trim() !== '' &&
            regionName?.trim() !== '' &&
            coordinates?.length > 0 &&
            severity?.trim() !== ''
        );
    }

    const submitCustomAlertConfigForm = async () => {
        if (selectedCamera?.id) {
            try {
                const response: CustomAlertConfigType = await customAlertConfigService.addCustomAlertConfig(selectedCamera?.id, formData);
                if (response) {
                    setCustomAlertConfigDetails(response);
                    ToastService.showToast('Save Custom Configuration', `Custom configuration saved successfully`, 'success', 3000);
                }
            } catch (error: any) {
                const { errorCode, errorInfo } = error?.response?.data;
                let errorMessage = errorInfo || 'Something went wrong';
                ToastService.showToast('Login failed', errorMessage, 'error');
            }
        }
    }

    const drawnProximityAreaRenderer = useMemo(() => {
        // console.log('openDialog', openDialog);
        let updatedPoints = null;
        // const coordinates = formData?.coordinates || customAlertConfigDetails?.region?.coordinates;
        let coordinates = customAlertConfigDetails?.region?.coordinates || [];
        if (formData?.coordinates?.length) {
            coordinates = formData?.coordinates;
        }

        // if (customAlertConfigDetails?.region) {
        // const { coordinates } = customAlertConfigDetails.region || formData;
        updatedPoints = updateCoordinateListFromScaleToPercentage(coordinates);
        // }

        // let updatedCoordinates = coordinates.map(c => ({ coordinateX: parseFloat(c.coordinateX), coordinateY: parseFloat(c.coordinateY) }))
        let updatedCoordinates = coordinates;

        if (updatedCoordinates?.length) {
            return (
                <>
                    {/* <Quadrilateral
                        mediaType="video"
                        pointsVisible={false}
                        mediaSrc={floorHotspotVideo}
                        defaultCoordinates={updatedPoints}
                        showLinesAndFill={!!updatedPoints?.length} /> */}

                    <QuadrilateralOverlay
                        mediaUrl={floorHotspotVideo}
                        // mediaUrl={'https://antmedia.workersafety.aiotdemos.com:5443/WorkerSafetyDev/play.html?id=qualcomm-device-554'}
                        // isIframe={true}
                        isVideo={true}
                        canDrag={false}
                        showLines={true}
                        initialPoints={updatedCoordinates}
                    />
                </>
            );
        }
        return (
            // <Quadrilateral
            //     mediaType="video"
            //     pointsVisible={false}
            //     mediaSrc={floorHotspotVideo}
            //     showLinesAndFill={!!updatedPoints?.length} />
            <>
                <QuadrilateralOverlay
                    mediaUrl={floorHotspotVideo}
                    // mediaUrl={'https://antmedia.workersafety.aiotdemos.com:5443/WorkerSafetyDev/play.html?id=qualcomm-device-554'}
                    // isIframe={true}
                    isVideo={true}
                    canDrag={false}
                    showLines={false}
                />
            </>
        );

        // }, [customAlertConfigDetails, handleCoordinatesChange]);
    }, [customAlertConfigDetails, formData?.coordinates, handleCoordinatesChange, floorHotspotVideo, updateCoordinateListFromScaleToPercentage]);

    // const updateCheckboxRenderer = useMemo(() => {
    //     return (
    //         <CustomCheckbox
    //             checked={!!formData?.enabled}
    //             onChange={handleOnChangeCustomAlertCheckbox} />
    //     )
    // }, [formData])

    const customAlertRenderer = () => {
        return (
            <>
                <section className="cam-view">
                    <header className='alert-action-header'>
                        <article>
                            <CustomCheckbox
                                checked={!!formData?.enabled}
                                onChange={handleOnChangeCustomAlertCheckbox} />
                            <span>Custom Alerts - Detection Zone </span>
                        </article>

                        <CustomButton
                            text='Create Proximity Area'
                            disabled={!formData?.enabled}
                            onClick={handleCreateProximityAreaBtnClick} />
                    </header>

                    <figure>
                        {drawnProximityAreaRenderer}
                    </figure>
                </section>

                <aside className="alert-details">
                    <header>Alert details</header>
                    <div className="input-block">
                        <label>Alert name</label>
                        <input type="text" name=""
                            placeholder="Enter alert name"
                            onChange={handleChangeAlertName}
                            value={formData.alertName}
                            disabled={!formData?.coordinates?.length} />
                    </div>
                    <div className="input-block">
                        <label>Severity level</label>
                        <CustomSelect
                            options={['Severity Level 1']}
                            placeholder={'Select security level'}
                            disabled={!formData?.coordinates?.length}
                            selectedOption={formData.severity}
                            onChange={(option: string): void => {
                                console.log(option)
                            }} />
                    </div>
                </aside>
            </>
        )
    };

    return (
        <>
            <section className="custom-alert-config">
                <section className="custom-alert-block">
                    {customAlertRenderer()}
                </section>
                <aside className="custom-alert-action">
                    <CustomButton text='Save Custom Configuration' disabled={!validateForm()} onClick={submitCustomAlertConfigForm} />
                </aside>
            </section>
            {customDialogRenderer()}
        </>
    );
}

export default CustomAlertConfig;